/**
 * These styles are brought to us from our frients at typecast.com
 * [1]: http://typecast.com/blog/a-more-modern-scale-for-web-typography
 */

body {
  font-size: 100%;
}

body,
caption,
th,
td,
input,
textarea,
select,
option,
legend,
fieldset,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size-adjust: 0.5;
}

body {
  font-size: 1em;
  line-height: 1.25;
}

@media (min-width: 43.75em) {
  body {
    font-size: 1em;
    line-height: 1.375;
  }
}

h1 {
  font-size: 2em;
  line-height: 1.25;
}

@media (min-width: 43.75em) {
  h1 {
    font-size: 2.5em;
    line-height: 1.125;
  }
}

@media (min-width: 56.25em) {
  h1 {
    font-size: 3em;
    line-height: 1.05;
  }
}

h2 {
  font-size: 1.625em;
  line-height: 1.15384615;
}

@media (min-width: 43.75em) {
  h2 {
    font-size: 2em;
    line-height: 1.25;
  }
}

@media (min-width: 56.25em) {
  h2 {
    font-size: 2.25em;
    line-height: 1.25;
  }
}

h3 {
  font-size: 1.375em;
  line-height: 1.13636364;
}

@media (min-width: 43.75em) {
  h3 {
    font-size: 1.5em;
    line-height: 1.25;
  }
}

@media (min-width: 56.25em) {
  h3 {
    font-size: 1.75em;
    line-height: 1.25;
  }
}

h4 {
  font-size: 1.125em;
  line-height: 1.11111111;
}

@media (min-width: 43.75em) {
  h4 {
    line-height: 1.22222222;
  }
}

blockquote {
  font-size: 1.25em;
  line-height: 1.25;
}

@media (min-width: 43.75em) {
  blockquote {
    font-size: 1.5em;
    line-height: 1.45833333;
  }
}
